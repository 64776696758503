
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@100;200;300;400;500;600;700;800&display=swap'); */
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css");
:root {
  --brand-blue: #030c8b;
  --brand-yellow: #feff36;
}

html,
body  {
  font-family: 'Poppins', sans-serif !important; 
   width: 100vw !important;
  overflow-x: hidden !important;
  /* color: black !important */
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: rgb(82, 82, 82) !important;
  border-radius: 15px;
}
::-webkit-scrollbar-track {
  background: transparent !important;
}
.student-name, .academic-year{
  font-weight: 600 !important;
}
.download-field{
  margin-top: 10px;
}
.download-field > button{
color: white;
background-color: green;
border: 1px solid green;
padding: 7px 15px;
text-transform: capitalize;
border-radius: 10px;
}
.stamp-div{
  position: relative;
  height: 155px;
}
.z-in {
  z-index: 999 !important;
}
.senior-stamp{
  background-image: url(/public/images/furdaus-stamp.jpg);
  background-repeat: no-repeat;
  background-position: right !important;
  background-size: 100px;
  margin-top: -10px !important;
}
.stamp-img{
  width: 150px;
  height: 150px;
  display: block;
  position: absolute;
 right: 0 !important;
 margin-top: -30px;


}
.stamp-img img{
object-fit: contain;
object-position: center;
width: 100%;
height: 100%;
}
.signature-img{
  width:70px;
  height: 15px;
  margin-top: -7px;
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
.react-router-link {
  text-decoration: none !important;
  color: inherit !important;
}
.nav-link {
  cursor: pointer !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
}

.overlay-wrapper {
  top: 0;
  width: 80%;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  right: 0;
}

.overlay-options {
  max-width: 500px;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.table-div,
.not-found {
  /* overflow-x: scroll; */
  height: fit-content !important;
}
.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
}


.view-button {
  color: blue;
  border: 0 !important;
  background: white !important;
  font-size: 13px;
  font-weight: 600;

}
.update-button {
  background: white !important;
  font-size: 13px !important;
  border: 0 !important;
  padding: 0 !important;
  font-weight: 600 !important;
  color: black !important;
}
 .delete-button {
  color: red !important;
  border: 0 !important;
  padding: 0 !important;
  /* border-radius: 10px; */
  background: transparent !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}
.activate-button{
  color: green !important;
  border: 0 !important;
  padding: 0 !important;
  /* border-radius: 10px; */
  background: transparent !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}
.deactivate-button{
  color: red !important;
  border: 0 !important;
  padding: 0 !important;
  /* border-radius: 10px; */
  background: transparent !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}
.deactivate-btn{
  color: red !important;
  border: 1px solid red;
  border-radius: 20px;
  padding: 3px 10px;
  background: transparent !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.pagination > li {
  font-size: 12px;
 color: grey;
  transition: 0.3s;
}
.pagination > .active {
  color: black;
  font-weight: 600 !important;
}

.search-field > .icon {
font-size: 20px !important;
}
.search-field > input {
 
  border: none;
}

.pre-header {
  /* background-color: #eff4fb; */
  color: #020d9b !important;
  padding: 7px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 14px !important;
  width: fit-content;
  font-weight: 500 !important;
}
.reopens{
  font-weight: 600 !important;
  color: black;
}
.csv-button{
  background-color: green;
  color: white;
  border: 1px solid green;
  padding: 5px 10px;
  font-weight: 600;
  height: fit-content !important;
  border-radius: 10px;
}
@media screen and (max-width: 1100px) {
  .overlay-wrapper{
    width: 100% !important;

  }
}

